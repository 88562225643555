// import firebase from 'firebase/app';
import firebase from 'firebase/compat/app';
// import 'firebase/analytics'
// import 'firebase/messaging';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { baseUrl } from './connections';
import axios from 'axios'
// import { useSelector } from 'react-redux';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import firebaseMessaging from "./firebase-messaging-sw"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
    apiKey: "AIzaSyDjtcHY-JvPtrcBHUBXBZMU-8Xk249aAgM",
    authDomain: "jobportal-7f5c0.firebaseapp.com",
    projectId: "jobportal-7f5c0",
    storageBucket: "jobportal-7f5c0.appspot.com",
    messagingSenderId: "852842205421",
    appId: "1:852842205421:web:b6702f099af8ec063b7ce4",
    measurementId: "G-P6E714RPNQ"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const getMes = (setTokenFound, pubnub) => {
// const app1 = initializeApp(firebaseConfig);
// const analytics1 = getAnalytics(app);

// const messaging = getMessaging(app1);
// const { currentUser } = useSelector((state) => state.auth)


getToken(messaging, { vapidKey: 'BPQYO1GMtOEKb4Gm4Zmhr7kzbYCKZgeH-UMKmdDR0EhwMJ6OTqBSvBiQme7Mwam8dIQi_Iyn08UZAtAYnWT_jNU' }).then((currentToken) => {
    if (currentToken) {
      // () => setTokenFound(true);

    // Send the token to your server and update the UI if necessary
      let userdata = JSON.parse(localStorage.getItem("user"));
      userdata = userdata != null ? userdata : false;
      if (userdata && userdata && userdata.uuid){
        const config1 = {
          method: 'post',
          url:`${baseUrl}/firebase/save`,
          data: {
            firebasetoken: currentToken,   
            device_type: "web",
            user_uuid : userdata?.uuid
          },
          headers: {
            'x-auth-token': userdata.token
          }
        };
        axios(config1).then((resp) => {
          console.log(resp)
        })
          .catch(err => {
            console.log(err)

          })

          // pubnub.push.addChannels({
          //   channels: ['Suppor Mista'],
          //   device: currentToken,
          //   pushGateway: 'gcm', // For Android
          // });
      }
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    setTokenFound(true);
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
}); 
}
export const onMessageListener = () =>

  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
