/** @jsxImportSource @emotion/react */
import { useEffect, useState, useContext, useRef } from "react";
import { Typography, Drawer } from "@mui/material";
import AppBar from "../../components/AppBar";
import ReebokSVG from "../../icons/reebok";
import AdidasSvg from "../../icons/adidas";
import NikeSVG from "../../icons/nike";
import Grid from '@mui/material/Grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { useGetNavItems } from "../Home/appbarNav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useGetRewardsScreenStyles } from "./index.styles";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import BrandIconCard from "../../components/BrandIconCard";
import OfferCard from "../../components/OfferCard";
// import AppFooter from "../../components/AppFooter";
import { useFavourites } from "../../hooks/services/useFavourites";
import { Radio } from "antd";
import uuid from "react-uuid";
import { useMail } from "../../hooks/services/useMail";
import RunCounter from "../../components/RunCounter";
import Snackbar from "../../components/Snackbar";
import BackButton from "../../components/BackButton";
import { useInterval } from "../../hooks/useInterval";
import { SearchOutlined } from "@mui/icons-material";
import useGetColors from "../../utils/colors";
import { useLocation, useNavigate } from "react-router-dom";
import Footer1 from "../../components/Footer/Footer";
import axios from "axios";
import { baseUrl } from "../../connections";



const colorsForBrands = ["#F2FCFF", "#F9FFF2", "#FFF2FB", "#FFEBDC"];
const arr = new Array(14).fill("").map((item, idx) => idx);
const brandSvgs = [NikeSVG, ReebokSVG, AdidasSvg];

const Favourites = () => {

  const navigate = useNavigate();

  const location = useLocation();

  const classes = useGetRewardsScreenStyles();
  const { userDetails } = useContext(UserContext);
  const { offerCardMultiColors: colors } = useGetColors();
  const [redeemedOfferIndexes, setRedeemedOfferIndexes] = useState([]);
  const { brandData = [], mailData = [], setFavPayload, setUpdateBrandFav, setUpdateMailFav, error } = useFavourites();
  const options = ["Brands", "Mails"];
  const [option, setOption] = useState(options[0]);
  const [searchValue, setSearchValue] = useState("");
  const offerDetailsCardRef = useRef(null);
  const [seconds, setSeconds] = useState(0);
  const { mailList, isRedeem, redeemMessage, redeemError, setMailPayload, mailHTML, setMailURL, setUpdateMailRedeem } = useMail();
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerContentRoute, setDrawerContentRoute] = useState("search");
  const [offerCard, setOfferCard] = useState({});
  const [selectedOfferIndex, setSelectedOfferIndex] = useState();

  const [numPages, setNumPages] = useState(1);
  const [isAnimate, setIsAnimate] = useState(true);

  const isOfferContentDrawerActive = drawerContentRoute === "offerCard";

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { start, stop } = useInterval((count) => {
    setSeconds(count);
    if (count === 18) {
      setSeconds(0);
      setIsAnimate(true);
      stop();
    }
  });

  const [mailSubscribeUpdate, setmailSubscribeUpdate] = useState(false);

  useEffect(() => {
    if (userDetails) setFavPayload(`pagenum=0&limit=100&user_uuid=${userDetails?.uuid}&country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${userDetails?.address?.city}&county_uuid=${userDetails?.address?.county}`);
  }, [userDetails, mailSubscribeUpdate]);

  const onOptionChange = ({ target: { value } }) => {
    setOption(value);
    setSearchValue("");
  };

  useEffect(() => {
    if (isOfferContentDrawerActive) {
      start();
    }
  }, [isOfferContentDrawerActive]);

  const toggleDrawer = (event) => {
    if (
      (event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")) ||
      isOfferContentDrawerActive
    ) {
      return;
    }
    setShowDrawer(false);
  };

  const onFavouriteToggle = (e, uuid, type) => {
    e.preventDefault();
    type === 'brand' ? setUpdateBrandFav({
      user_uuid: userDetails?.uuid,
      brand_uuid: uuid
    }) : setUpdateMailFav({
      user_uuid: userDetails?.uuid,
      mail_uuid: uuid
    })
  }

  const DrawerOfferCardContent = () => (

    <div css={classes.offerDetailsContentWrapper} ref={offerDetailsCardRef}>

      {offerCard?.type === "paid" && ((redeemError === false && isAnimate) || (redeemError === true) || offerCard?.is_seen === 'true') && <IconButton aria-label="back" size="medium" css={classes.backArrow} onClick={(e) => { setDrawerContentRoute('search'); setShowDrawer(false) }}>
        <ArrowBackIosIcon /> Back
      </IconButton>}
      {offerCard?.type === "regular" && <IconButton aria-label="back" size="medium" css={classes.backArrow} onClick={(e) => { setDrawerContentRoute('search'); setShowDrawer(false) }}>
        <ArrowBackIosIcon /> Back
      </IconButton>}
      <div css={classes.timerWrapper}>
        <div>
          {offerCard?.type === "paid" && redeemError === false && offerCard?.is_seen === "false" && seconds !== 0 && <div className={classes.progress} style={{ position: 'relative' }}>
            <FontAwesomeIcon icon={faSpinner} className={isAnimate ? 'fa-4x' : `fa-pulse fa-spin-reverse fa-4x`} color="#1890ff" />
            <Typography variant="caption" component="div" color="text.secondary" style={{ position: 'absolute', top: '20px', left: '14px' }}>
              {`${18 - (seconds === 1 ? 18 : seconds)}sec`}
            </Typography>
          </div>}
        </div>
      </div>
      <Typography css={classes.subText}>
        {offerCard?.message}
      </Typography>
      {offerCard?.mime_type === 'text/html' && <div style={{ maxWidth: '98%', margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: mailHTML }} />}
      {offerCard?.mime_type === 'application/pdf' && <Document file={offerCard?.image_url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={numPages} />
      </Document>}
      {offerCard?.mime_type === 'image/png' || offerCard?.mime_type === 'image/jpeg' && <img css={classes.offerImage} src={offerCard?.image_url} alt="offer details" />}
    </div>
    // <div css={classes.offerDetailsContentWrapper} ref={offerDetailsCardRef}>
    //   <IconButton aria-label="back" size="medium" css={classes.backArrow} onClick={(e) => setShowDrawer(false)}>
    //     <ArrowBackIosIcon />
    //   </IconButton>
    //   <div css={classes.timerWrapper}>
    //     <RunCounter value={seconds} />
    //   </div>
    //   <Typography css={classes.subText}>
    //     {offerCard?.message}
    //   </Typography>
    //   <img crossorigin="anonymous" css={classes.offerImage} src={offerCard?.image_url} alt="offer details" />
    // </div>
  );

  useEffect(() => {
    // if(redeemError === false && offerCard?.type === "paid") {
    //   setShowDrawer(true)
    // }
    if (offerCard?.type === "paid") {
      setShowDrawer(true);
    }


  }, [redeemError, isRedeem])

  const handleNavLinksClick = (route, item) => {
    // setOfferCard(item);
    // setShowDrawer(true);
    // setDrawerContentRoute(route);
    // setUpdateMailRedeem({
    //   user_uuid : userDetails?.uuid,
    //   mail_uuid : item?.uuid
    //   });

    if (item?.mime_type === 'text/html') {
      const url = item?.image_url?.split('/');
      setMailURL({ html_url: url.slice(-1)[0] })
    }
    setOfferCard(item);
    // setShowDrawer(true);
    // setDrawerContentRoute(route);
    setDrawerContentRoute(route);
    {
      item?.type === "regular" &&
        setShowDrawer(true);
    }

    {
      item?.type === "paid" &&
        setUpdateMailRedeem({
          user_uuid: userDetails?.uuid,
          mail_uuid: item?.uuid
        });
    }

    setIsAnimate(false);


    //****Location Check ******/

    // if (item?.city_uuid !== '') {
    //   if ((item?.country_uuid === userDetails?.address?.country) && (item?.state_uuid === userDetails?.address?.state) &&
    //     (item?.county_uuid === userDetails?.address?.county) && (item?.city_uuid === userDetails?.address?.city)) {
    //       setOfferCard(item);
    //       setShowDrawer(true);
    //       setDrawerContentRoute(route);
    //       setUpdateMailRedeem({
    //         user_uuid : userDetails?.uuid,
    //         mail_uuid : item?.uuid
    //         });
    //   }
    //   else {
    //     // setLocationCheck(true)
    //   }
    // }
    // else if (item?.county_uuid !== '') {
    //   if ((item?.country_uuid === userDetails?.address?.country) && (item?.state_uuid === userDetails?.address?.state) &&
    //     (item?.county_uuid === userDetails?.address?.county)) {
    //       setOfferCard(item);
    //       setShowDrawer(true);
    //       setDrawerContentRoute(route);
    //       setUpdateMailRedeem({
    //         user_uuid : userDetails?.uuid,
    //         mail_uuid : item?.uuid
    //         });
    //   }
    //   else {
    //     // setLocationCheck(true)
    //   }
    // }
    // else if (item?.state_uuid !== '') {
    //   if ((item?.country_uuid === userDetails?.address?.country) && (item?.state_uuid === userDetails?.address?.state)) {
    //     setOfferCard(item);
    //     setShowDrawer(true);
    //     setDrawerContentRoute(route);
    //     setUpdateMailRedeem({
    //       user_uuid : userDetails?.uuid,
    //       mail_uuid : item?.uuid
    //       });
    //   }
    //   else {
    //     // setLocationCheck(true)
    //   }
    // }
    // else if (item?.country_uuid !== '') {
    //   if (item?.country_uuid === userDetails?.address?.country) {
    //     setOfferCard(item);
    //     setShowDrawer(true);
    //     setDrawerContentRoute(route);
    //     setUpdateMailRedeem({
    //       user_uuid : userDetails?.uuid,
    //       mail_uuid : item?.uuid
    //       });
    //   }
    //   else {
    //     // setLocationCheck(true)
    //   }
    // }

  };

  const renderDrawerContent = () => {
    switch (drawerContentRoute) {
      case "offerCard":
        return <DrawerOfferCardContent />;
    }
  };

  const onSearchInput = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value)
    if (e.target.value.length > 2) {
      setFavPayload(`user_uuid=${userDetails?.uuid}&country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${userDetails?.address?.city}&county_uuid=${userDetails?.address?.county}&search=${e.target.value}`);
    }
    if (e.target.value === '') setFavPayload(`pagenum=0&limit=100&user_uuid=${userDetails?.uuid}&country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${userDetails?.address?.city}&county_uuid=${userDetails?.address?.county}`);
  }

  const [subscribeshow, setSubscribeShow] = useState(false);
  const unSubscribeMail = (dataSub) => {
    const payload = {
      "mail_uuid": dataSub?.uuid,
      "user_uuid": userDetails?.uuid
    }
    axios.put(`${baseUrl}/mail/unsubscribe`, payload, {
      headers: {
        "x-auth-token": userDetails?.token
      }
    })
      .then((res) => {
        setSubscribeShow(false);
        setmailSubscribeUpdate(mailSubscribeUpdate ? false : true);
        if (userDetails) setFavPayload(`pagenum=0&limit=100&user_uuid=${userDetails?.uuid}&country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${userDetails?.address?.city}&county_uuid=${userDetails?.address?.county}`);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div css={classes.pageContainer}>
      <AppBar navItems={useGetNavItems({})} />
      <div css={classes.titleBanner}>
        <div style={{ justifyContent: 'flex-end' }}><BackButton /></div>
        <div><h1>My Favourites</h1></div>
        <div></div>
      </div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={4}>
          <div css={classes.subWraper}>
            <div css={classes.radioGroup}>
              <Radio.Group
                options={options}
                onChange={onOptionChange}
                value={option}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} style={{ marginRight: "20px", marginLeft: "20px" }}>
          <div css={classes.searchWrapper}>
            <SearchOutlined sx={classes.searchIcon} />
            <input
              css={classes.search}
              placeholder={`Search ${option}`}
              onChange={onSearchInput}
            />
          </div>
        </Grid>
      </Grid>


      <div css={classes.brandCardsInSearchWrapper}>
        {option === options[0] ? (
          <>
            {brandData && brandData
              .map((item, i) => {
                const BrandIcn = brandSvgs[i % brandSvgs.length].icn;
                const address = `${item?.company_name}, ${item?.address1} -${item?.pincode}`;
                return (
                  <div style={{ cursor: 'pointer' }}>
                    <BrandIconCard
                      key={uuid()}
                      color={colorsForBrands[i % colorsForBrands.length]}
                      size={150}
                      isFavourite={true}
                      onSelect={() => navigate("/home", {
                        state: {
                          data: {
                            selCountry: location?.state?.selCountry,
                            selState: location?.state?.selState,
                            selCity: location?.state?.selCity,
                            selCounty: location?.state?.selCounty,
                            selCategory: item?.category_uuid[0],
                          },
                          id: item?.uuid
                        }
                      })}
                      onFavouriteToggle={(e) => onFavouriteToggle(e, item?.uuid, 'brand')}
                      showCardAction
                      address={address}
                    >
                      {item?.logo_url && <img width={70} src={item?.logo_url} alt={item?.slug} />}
                      {/* {item?.logo_url ? <img width={70} src={item?.logo_url} alt={item?.slug} /> : <BrandIcn width="70%" height="70%" fill="black" />} */}
                    </BrandIconCard>
                  </div>
                );
              })}
          </>
        ) : (
          <>
            {mailData && mailData
              .map((item, i) => {
                let j = i % 4;
                return (
                  <div css={classes.categoryWrapper} key={uuid()}>
                    <OfferCard
                      key={uuid()}
                      containerStyles={{
                        backgroundColor: colors[j]
                      }}
                      isRedeemed={item?.is_seen === 'true' ? true : false}
                      item={item}
                      UnsubscribeMailClick={() => unSubscribeMail(item)}
                      show={subscribeshow}
                      setShow={(e1) => setSubscribeShow(e1)}
                      isFavourite={true}
                      onFavouriteMailToggle={(e) => onFavouriteToggle(e, item?.uuid)}
                      onClick={() => {
                        // if (item?.is_seen === 'true') return;
                        // if (!redeemedOfferIndexes.includes(i)) {
                        //   setSelectedOfferIndex(i);
                        //   handleNavLinksClick("offerCard", item);
                        // }
                        setSelectedOfferIndex(i);
                        handleNavLinksClick("offerCard", item);
                      }}
                    />
                    <span css={classes.categoryText}>{item?.name}</span>
                  </div>
                );
              })}
          </>
        )}

      </div>
      {/* <AppFooter /> */}
      <Footer1 />
      <Drawer
        anchor="top"
        open={showDrawer}
        onClose={toggleDrawer}
        PaperProps={{
          sx: classes.drawer,
        }}
        sx={[classes.drawerContainer, { zIndex: 1277 }]}

      >
        {renderDrawerContent()}
      </Drawer>
      {isRedeem && <Snackbar message={redeemMessage} isOpen={isRedeem} severity={redeemError ? 'error' : 'success'} />}
    </div >
  );
};

export default Favourites;
