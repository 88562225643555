/** @jsxImportSource @emotion/react */
import { useState, memo, useEffect } from "react";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import DirectionsOutlinedIcon from "@mui/icons-material/DirectionsOutlined";
import { useGetBrandIconCardStyles } from "./index.styles";
import Button from "../Button";
import { FavoriteOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

const BrandIconCard = ({
  rounded,
  src,
  src1,
  size,
  maxSize,
  color,
  isFavourite = false,
  isActive = false,
  onFavouriteToggle,
  onSelect,
  showDrawer,
  drawer,
  onActionClick,
  showCardAction,
  children,
  address=""
}) => {
  const [isFav, setIsFav] = useState(isFavourite);
  const [isSelected, setIsSelected] = useState(isActive);

  const classes = useGetBrandIconCardStyles({
    rounded,
    size,
    color,
    maxSize,
    isSelected: isActive,
  });

  const onClickHandler = () => {
    setIsSelected((prev) => !prev);
    onSelect(!isSelected);
    if(drawer === true) showDrawer(false);
  };

  const onFavClick = async (e) => {
    e.stopPropagation();
    const favAddRes = await onFavouriteToggle(e, !isFav);
    setIsFav(!isFav);
  };

  return (
    <div css={classes.selectionWrapper} onClick={onClickHandler}>
      <div css={classes.cardWrapper}>
        <div css={classes.imageView}>
          {/* {src1?.logo_url && <img src={src1?.logo_url} alt={src} key={src} style={{backgroundRepeat : 'no-repeat', width : '55%', height : 'auto'}}/>} */}
          {children}
        </div>
          <IconButton css={classes.favIcon} onClick={onFavClick} >
            {isFav ? (
              <FavoriteOutlined sx={{ color: "red", fontSize: '18px' }} />
            ) : (
              <FavoriteBorderOutlinedIcon sx={{ color: "red", fontSize: '18px' }} />
            )}
          </IconButton>
      </div>
      {showCardAction && !rounded && (
        <div css={classes.actionWrapper}>
          <Button
            variant="text"
            onClick={(e) => {
              e.stopPropagation();
              onActionClick(e);
            }}
          >
              <Tooltip title={address}>
            <div css={classes.btnWrapper}>
                <DirectionsOutlinedIcon />
                <span>View Address</span>
            </div>
              </Tooltip>
          </Button>
        </div>
      )}
    </div>
  );
};

BrandIconCard.defaultProps = {
  onSelect: () => void 0,
  onActionClick: () => void 0,
  onFavouriteToggle: (res) => res,
  showCardAction: false,
  isFavourite: false,
};

export default memo(BrandIconCard);
