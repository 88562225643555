import { useEffect, useState } from "react";
import { baseUrl } from "../../connections";

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [payload, setPayload] = useState(null);
  const [message, setMessage] = useState('');
  const [popupADD, setpopupADD] = useState(false);
  const [bingoDetails, setBingo] = useState('')

  useEffect(() => {
    setData(undefined)
    if (payload && payload.username && payload.password) {
      setError(null)
      setIsLoading(true);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const body = JSON.stringify(payload);

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: body,
        redirect: "follow",
      };

      fetch(`${baseUrl}/user/login`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) => {
          setData({ ...result.data, ...result.bingoDetail, token: result.token });
          setError(false);
          setMessage(result?.data?.user_verified ? 'User Logged in Successfully' : "User Not Verified");
          setIsLoading(false);
          localStorage.setItem("user", JSON.stringify(result?.data?.data))
          setpopupADD(true)
        })
        .catch((err) => {
          setData(undefined)
          setError(true);
          setMessage(err);
          setIsLoading(false);
        });
    }
  }, [payload]);

  return {
    data,
    isLoading,
    error,
    message,
    popupADD,
    setPayload,
    setpopupADD,
    setData
  };
};
