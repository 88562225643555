import { useEffect, useState, useContext } from "react";
import { baseUrl } from "../../connections";
import { UserContext } from "../../context/UserContext";

export const Notificationconsumer = () => {
  const { userDetails } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [payloadsdata, setPayloadsdata] = useState('');
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [stateChange, setStateChange] = useState(false)
  const [count ,setcount ] = useState(10)
  const [unread,setunread] = useState('')

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("x-auth-token", userDetails?.token);

  const onNtificationLoad = () => {
    setIsLoading(true);
      const params = JSON.stringify(payloadsdata,page,limit,search);
      const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      fetch(`${baseUrl}/notification/list/consumer?page=${page}&limit=10&user_uuid=${payloadsdata}`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) =>{
          setData(result?.data);
          setError(false);
          setcount(result?.count);
          setunread(result)
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
  }
  useEffect(() => {
    if (payloadsdata) {
      onNtificationLoad();
    }
    // const interval = setInterval(() => onNtificationLoad(), 180000)
    // return () => {
    //   clearInterval(interval);
    // }
  }, [payloadsdata]);

  useEffect(() => {
    if(payloadsdata) {
    setIsLoading(true);
      const params = JSON.stringify(payloadsdata,page,limit,search);
      const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      fetch(`${baseUrl}/notification/list/consumer?page=${page}&limit=${limit}&user_uuid=${payloadsdata}`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) =>{
          setData(result?.data);
          setError(false);
          setcount(result?.count);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
      }
  }, [stateChange, setPayloadsdata, page, limit, search]);

  return {
    noticationdata: data,
    isLoading,
    error,
    setPayloadsdata,
    setpage,
    setlimit,
    setsearch,
    count,
    unread,
    setStateChange,
    stateChange,
    page,
    limit
  };
};
