// import React, { useState, useEffect } from "react";
// import packageJson from "../package.json";
// import moment from "moment";

// const buildDateGreaterThan = (latestDate, currentDate) => {
//   const momLatestDateTime = moment(latestDate);
//   const momCurrentDateTime = moment(currentDate);

//   if (momLatestDateTime.isAfter(momCurrentDateTime)) {
//     return true;
//   } else {
//     return false;
//   }
// };

// function withClearCache(Component) {
//   function ClearCacheComponent(props) {
//     const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);


//     useEffect(() => {
//       fetch("/meta.json")
//         .then((response) => response.json())
//         .then((meta) => {
//           const latestVersionDate = meta.buildDate;
//           const currentVersionDate = packageJson.buildDate;

//           const shouldForceRefresh = buildDateGreaterThan(
//             latestVersionDate,
//             currentVersionDate
//           );
//           if (shouldForceRefresh) {
//             setIsLatestBuildDate(false);
//             refreshCacheAndReload();
//           } else {
//             setIsLatestBuildDate(true);
//           }
//         });
//     }, []);

//     const refreshCacheAndReload = () => {
//       if (caches) {
//         // Service worker cache should be cleared with caches.delete()
//         caches.keys().then((names) => {
//           for (const name of names) {
//             caches.delete(name);
//           }
//         });
//       }
//       // delete browser cache and hard reload
//       window.location.reload(true);
//     };

//     return (
//       <React.Fragment>
//         {isLatestBuildDate ? <Component {...props} /> : null}
//       </React.Fragment>
//     );
//   }

//   return ClearCacheComponent;
// }

// export default withClearCache;

import React, { useState, useEffect, useCallback } from 'react';
import packageJson from '../package.json';

const semverGreaterThan = (versionA, versionB) => {
  // code from the original snippet goes here
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const CacheBuster = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  const refreshCacheAndReload = useCallback(() => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  }, []);

  useEffect(() => {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          setIsLatestVersion(false);
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          setIsLatestVersion(true);
        }

        setLoading(false);
      });
  }, []);

  return children({ loading, isLatestVersion, refreshCacheAndReload });
};

export default CacheBuster;
