/** @jsxImportSource @emotion/react */
import { useEffect, useState, useContext, useRef, Fragment } from "react";
import { Typography, Drawer } from "@mui/material";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import AppBar from "../../components/AppBar";
import ReebokSVG from "../../icons/reebok";
import AdidasSvg from "../../icons/adidas";
import NikeSVG from "../../icons/nike";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { useGetNavItems } from "../Home/appbarNav";
import { useGetRewardsScreenStyles } from "./index.styles";
import BrandIconCard from "../../components/BrandIconCard";
// import AppFooter from "../../components/AppFooter";
import { useMail } from "../../hooks/services/useMail";
import { useBrand } from "../../hooks/services/useBrand";
import RunCounter from "../../components/RunCounter";
import Snackbar from "../../components/Snackbar";
// import BackButton from "../../components/BackButton";
import { useInterval } from "../../hooks/useInterval";
import { SearchOutlined } from "@mui/icons-material";
import Pagination from '../../components/Pagination';
import Footer1 from "../../components/Footer/Footer";
// import ErrorBoundary from "./errorBoundary";


const colorsForBrands = ["#F2FCFF", "#F9FFF2", "#FFF2FB", "#FFEBDC"];
const arr = new Array(14).fill("").map((item, idx) => idx);
const brandSvgs = [NikeSVG, ReebokSVG, AdidasSvg];

const colorsForCategories = [
  "#EEF8FF",
  "#EEFFF9",
  "#FEEEFF",
  "#FFEEF3",
  "#FFFDEE",
  "#FFF4EE",
];



let PageSize = 20;

const MoreBrands = () => {
  const classes = useGetRewardsScreenStyles();
  const navigate = useNavigate();
  const location = useLocation()
  const { userDetails, activeBrand, setActiveBrand } = useContext(UserContext);
  const [redeemedOfferIndexes, setRedeemedOfferIndexes] = useState([]);
  const options = ["Brands", "Mails"];
  const [option, setOption] = useState(options[0]);
  const [searchValue, setSearchValue] = useState("");
  const offerDetailsCardRef = useRef(null);
  const [seconds, setSeconds] = useState(0);
  const { isRedeem, redeemMessage, redeemError, setUpdateMailRedeem } = useMail();
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerContentRoute, setDrawerContentRoute] = useState("search");
  const [offerCard, setOfferCard] = useState({});
  const { barndList, brandCount, setBrandPayload, setUpdateBrandFav } = useBrand();
  const [currentPage, setCurrentPage] = useState(0);
  const [selBrand, setSelBrand] = useState([...activeBrand]);

  const [searchParams, setSearchParams] = useSearchParams();
  const brandUuid = searchParams.get('brand_uuid');


  const isOfferContentDrawerActive = drawerContentRoute === "offerCard";

  const { start, stop } = useInterval((count) => {
    setSeconds(count);
    if (count === 10) {
      setSeconds(0);
      stop();
    }
  });

  useEffect(() => {
    if (brandUuid) onBrandSelectInitial(true, brandUuid)
  })
  useEffect(() => {
    setBrandPayload(`pagenum=${currentPage}&limit=${PageSize}&user_uuid=${userDetails?.uuid}&category_uuid=${location?.state?.selCategory}&country_uuid=${location?.state?.selCountry}&state_uuid=${location?.state?.selState}&city_uuid=${location?.state?.selCity}&county_uuid=${location?.state?.selCounty}`);
  }, [currentPage, setUpdateBrandFav]);

  useEffect(() => {
    if (isOfferContentDrawerActive) {
      start();
    }
  }, [isOfferContentDrawerActive]);

  const toggleDrawer = (event) => {
    if (
      (event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")) ||
      isOfferContentDrawerActive
    ) {
      return;
    }

    setShowDrawer(false);
  };

  const onBrandSelectInitial = (e, brandId) => {
    const brandList = [...selBrand];
    const brand = e ? [brandId] : selBrand.filter((el) => el !== brandId);
    setSelBrand([...brand]);
    setActiveBrand([...brand]);
  }

  const onBrandSelect = (e, brandId) => {
    const brandList = [...selBrand];
    const brand = e ? [brandId] : selBrand.filter((el) => el !== brandId);
    setSelBrand([...brand]);
    setActiveBrand([...brand]);
    navigate('/home', {
      state: {
        data: location?.state,
        id: brandId
      }
    })
  }

  const onFavouriteToggle = (e, uuid) => {
    e.preventDefault();
    setUpdateBrandFav({
      user_uuid: userDetails?.uuid,
      brand_uuid: uuid
    })
  }

  const DrawerOfferCardContent = () => (
    <div css={classes.offerDetailsContentWrapper} ref={offerDetailsCardRef}>
      <IconButton aria-label="back" size="medium" css={classes.backArrow} onClick={(e) => setShowDrawer(false)}>
        <ArrowBackIosIcon />
      </IconButton>
      <div css={classes.timerWrapper}>
        <RunCounter value={seconds} />
      </div>
      <Typography css={classes.subText}>
        {offerCard?.message}
      </Typography>
      <img css={classes.offerImage} src={offerCard?.image_url} alt="offer details" />
    </div>
  );

  const handleNavLinksClick = (route, item) => {
    setOfferCard(item);
    setShowDrawer(true);
    setDrawerContentRoute(route);
    setUpdateMailRedeem({
      user_uuid: userDetails?.uuid,
      mail_uuid: item?.uuid
    });
  };

  const renderDrawerContent = () => {
    switch (drawerContentRoute) {
      case "offerCard":
        return <DrawerOfferCardContent />;
    }
  };

  const onSearchInput = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value)
    if (e.target.value.length > 2) {
      setBrandPayload(`user_uuid=${userDetails?.uuid}&search=${e.target.value}&category_uuid=${location?.state?.selCategory}&country_uuid=${location?.state?.selCountry}&state_uuid=${location?.state?.selState}&city_uuid=${location?.state?.selCity}&county_uuid=${location?.state?.selCounty}`);
    }
    if (e.target.value === '') setBrandPayload(`pagenum=0&limit=10&user_uuid=${userDetails?.uuid}&category_uuid=${location?.state?.selCategory}&country_uuid=${location?.state?.selCountry}&state_uuid=${location?.state?.selState}&city_uuid=${location?.state?.selCity}&county_uuid=${location?.state?.selCounty}`);
  }

  return (
    // <ErrorBoundary>
      <div css={classes.pageContainer}>
        <AppBar navItems={useGetNavItems({})} />
        <div css={classes.titleBanner}>
          <div style={{ justifyContent: 'flex-end' }}>
            {/* <ArrowBackIosIcon /> Back */}
            <IconButton aria-label="back" size="medium" css={classes.backButton} onClick={() => navigate('/home', {
              state: {
                data: location?.state,
                id: brandUuid
              }
            })}>
              <ArrowBackIosIcon /> Back
            </IconButton>
            {/* <BackButton /> */}
          </div>
          <div><h1>Brands List</h1></div>
          <div></div>
        </div>
        <div css={classes.subWraper}>
          <div css={classes.searchWrapper}>
            <SearchOutlined sx={classes.searchIcon} />
            <input
              css={classes.search}
              placeholder={`Search ${option}`}
              onChange={onSearchInput}
            />
          </div>
        </div>
        <div css={classes.brandCardsInSearchWrapper}>
          <>
            {barndList && barndList
              .filter((item, i) => {
                if (!searchValue) {
                  return true;
                }
                return item?.company_name
                  .toLowerCase()
                  .startsWith(searchValue?.toLowerCase());
              })
              .map((item, i) => {
                if (!item?.is_active) return (<Fragment key={item?.uuid}></Fragment>);
                const BrandIcn = brandSvgs[i % brandSvgs.length].icn;
                const address = `${item?.company_name}, ${item?.address1},${item?.counties[0].name}, ${item?.states[0].name}-${item?.pincode}`;
                return (
                  <div style={{ cursor: 'pointer' }}>
                    <BrandIconCard
                      key={item?.uuid}
                      color={colorsForBrands[i % colorsForBrands.length]}
                      onSelect={(e) => onBrandSelect(e, item?.uuid)}
                      isActive={selBrand?.indexOf(item?.uuid) !== -1 || item.is_highlight === 'true' ? true : false}
                      size={150}
                      isFavourite={item?.is_faviourate === 'true' ? true : false}
                      onFavouriteToggle={(e) => onFavouriteToggle(e, item?.uuid)}
                      showCardAction
                      address={address}
                    >
                      <img width={70} src={item?.logo_url} alt={item?.slug} />
                      {/* {item?.logo_url ? <img width={70} src={item?.logo_url} alt={item?.slug} /> : <BrandIcn width="70%" height="70%" fill="black" />} */}
                    </BrandIconCard>
                  </div>
                );
              })}
          </>
          <Drawer
            anchor="top"
            open={showDrawer}
            onClose={toggleDrawer}
            PaperProps={{
              sx: classes.drawer,
            }}
            sx={classes.drawerContainer}
          >
            {renderDrawerContent()}
          </Drawer>
          {isRedeem && <Snackbar message={redeemMessage} isOpen={isRedeem} severity={redeemError ? 'error' : 'success'} />}
        </div>
        <div css={classes.paginationWraper}>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={brandCount}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />
        </div>
        {/* <AppFooter /> */}
        <Footer1 />
      </div>

  );
};

export default MoreBrands;
