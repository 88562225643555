/** @jsxImportSource @emotion/react */
import SignInTemplate from "../../templates/SignIn";
import TextBox from "../../components/TextBox";
import { useGetSignInFormStyles } from "./index.styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "../../components/Button";
import { useNavigate, Link } from "react-router-dom";
import { useLogin } from "../../hooks/services/useLogin";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import Snackbar from "../../components/Snackbar";
import uuid from "react-uuid";
import { REACT_APP_COOKIE_DOMAIN, REACT_APP_COOKIE_SECURE } from "../../connections";
import { Snackbar as MuiSnackbar, ThemeProvider } from "@mui/material";
import Alert from '@mui/material/Alert';
import { theme } from "../../components/Snackbar/index.styles";
import appLogo from "../../../src/assets/images/svg/appLogo.svg";
import moneymailslogo from "../../../src/assets/images/svg/money-mails-logo.f4f6aef6.png";
import Cookies from 'js-cookie';

import { useCookies } from "react-cookie";

const Login = () => {
  const classes = useGetSignInFormStyles();
  const navigate = useNavigate();
  const { userDetails, setUserDetails } = useContext(UserContext);

  const [mobile, setMobile] = useState({});
  const [password, setPassword] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const [userVerifyByAdmin, setUserVerifyByAdmin] = useState(false);
  const [mobileView, setMobileView] = useState(false);


  const { data, isLoading, error, message, setPayload } = useLogin();
  const [cookies, setCookie] = useCookies(["username", "token", "passcode"]);
  const [mobileError, setMobileError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [open, setOpen] = useState(false);
  const [errormessage, setErrorMessage] = useState('');

  const submitHandler = (e) => {
    e.preventDefault();

    if (!mobile.value && !password) {
      setMobileError('Please Enter Phone Number and Password');
      setErrorMessage('Please Enter Phone Number and Password ');
      setOpen(true)
      return;
    }

    if (!mobile.value) {
      setMobileError('Please Enter a Phone Number');
      setErrorMessage('Please Enter a Phone Number');
      setOpen(true)
      return;
    }

    // Validate password
    if (!password) {
      setPasswordError('Please Enter a Password');
      setErrorMessage('Please Enter a Password');
      setOpen(true)
      return;
    }

    setUserVerifyByAdmin(false)
    e.preventDefault();
    setPayload({
      username: mobile.code + mobile.value,
      password,
    });
    setIsLogging(true);
  };

  const onLoad = async (data) => {
    if (!data) return;
    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      avatar: data.avatar || data?.profile_pic,
      mobile: data.mobile,
      ssn: data?.ssn,
      id_proof: data?.id_proof,
      id_number: data?.id_number,
      gender: data?.gender,
      age: data?.age,
      intrests:data?.interests,
      // languages
      languages:data?.languages,
      religion: data?.religion,
      phone: {
        code: data?.mobile?.length === 11 ? data?.mobile.substr(0, 1) : data?.mobile.substr(0, 2),
        value: data?.mobile?.length === 11 ? data?.mobile.substr(1) : data?.mobile.substr(2),
      },
      primaryEmail: data.email,
      address: {
        location: data.address1,
        city: data.city,
        addressLine1: data.address2,
        state: data.state,
        county: data.county,
        country: data.country,
        apartmentNo: data.apartment_number,
        zip: data.pincode,
        landmark: data.landmark,
      },
      demographics: {
        race: data.ethnicity,
        dob: data.dob,
      },
      user_uuid: data?.uuid,
      uuid: data?.uuid,
      isAuthenticated: true,
      token: data.token,
      rewards: data?.rewards
    }
    setUserDetails(userData);
    localStorage.setItem("auth", JSON.stringify(true));
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("token", JSON.stringify(data.token));
    setTimeout(() => {

      if (data?.user_verified_by_admin === false) {
        setUserVerifyByAdmin(true)
        setTimeout(() => {
          navigate("/check-email")
        }, 1000)
      }
      else if (data?.is_admin_sent_mail === false) {
        navigate("/check-email")
      }
      else {
        data?.user_verified ? navigate("/home") : navigate("/verification");
      }
    }, 1000)
  }

  useEffect(() => {
    if (data && !error && !isLoading) {
      onLoad(data);
      const cookieOptions = { path: '/', secure: REACT_APP_COOKIE_SECURE, domain: REACT_APP_COOKIE_DOMAIN }
      setCookie("dInfoUserEmail", data.dInfoUserEmail, cookieOptions);
      setCookie("dInfoUserId", data.dInfoUserId, cookieOptions);
      setCookie("dInfoUserType", data.dInfoUserType, cookieOptions);

    }
  }, [data]);

  useEffect(() => {
    setTimeout(function () { if (isLogging) setIsLogging(false) }, 3000);
  }, [isLogging])

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 767
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [])

  return (
    <>
      <SignInTemplate>
        <div css={classes.container}>
          {mobileView &&
            <div>
              <img src={moneymailslogo} style={{width:'90%'}} alt="illustration" />
            </div>
          }
          <h2 css={classes.heading}>Login to your Account</h2>
          <form css={classes.formContainer} onSubmit={submitHandler}>
            <TextBox
              label="Phone Number"
              type="tel"
              value={mobile.value || ""}
              defaultCountryCode="1"
              onChange={(_e, val) => {
                setMobile(val);
                setMobileError('');
              }}
            />
            {/* {mobileError && <p style={{ color: "red" }} className="error-message">{mobileError}</p>} */}
            <div css={classes.pwdWrapper}>
              <TextBox
                type="password"
                label="Password"
                value={password}
                onChange={(_e, val) => {
                  setPassword(val);
                  setPasswordError('');
                }}
              />
              {/* {passwordError && <p style={{ color: "red" }} className="error-message">{passwordError}</p>} */}
              <Button
                type="button"
                variant="text"
                fullWidth
                color="secondary"
                sx={classes.forgotPwdBtn}
                onClick={() => navigate("/reset-password")}
              >
                Forgot Password?
              </Button>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ width: 1 / 3 }}
            // onClick={submitHandler}
            >
              <div css={classes.buttonContainer}>
                <span>Login</span>
                <ArrowForwardIcon />
              </div>
            </Button>
            <ThemeProvider theme={theme}>
              <MuiSnackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
                <Alert severity={'error'}>
                  {errormessage}
                </Alert>
              </MuiSnackbar>
            </ThemeProvider>
          </form>


          <Button
            type="button"
            variant="text"
            fullWidth
            color="secondary"
            sx={{ marginTop: "80px" }}
            onClick={() => navigate("/signup")}
          >
            Don't have an account? Sign up
          </Button>
          <div css={classes.terms}>
            <Link to="/terms" css={classes.links}>Terms & Conditions</Link>
            <Link to="/privacy" css={classes.links}>Privacy Policy</Link>
          </div>
        </div>
      </SignInTemplate>

      {/* {isLogging && !data?.user_verified && message && <Snackbar key={uuid()} message={message} isOpen={isLogging} severity={'error'} />} */}
      {/* {isLogging &&  message && <Snackbar key={uuid()} message={ data ? (!data?.user_verified_by_admin ? "User Not Verified By Admin" : message) : message} isOpen={isLogging} severity={data ? (!data?.user_verified_by_admin ? 'error' : 'success') : (data?.user_verified ? 'success' : 'error')} />} */}
      {error && message && <Snackbar key={uuid()} message={message} isOpen={isLogging} severity={'error'} />}
      {isLogging && data?.user_verified_by_admin && data?.is_admin_sent_mail && message && <Snackbar key={uuid()} message={message} isOpen={isLogging} severity={data?.user_verified ? 'success' : 'warning'} />}
      {isLogging && data?.user_verified_by_admin && !data?.is_admin_sent_mail && message && <Snackbar key={uuid()} message={message} isOpen={isLogging} severity={'success'} />}
      {isLogging && !data?.user_verified_by_admin && userVerifyByAdmin && <Snackbar key={uuid()} message="User Not Verified By Admin" isOpen={userVerifyByAdmin} severity='error' />}
    </>
  );
};

export default Login;
