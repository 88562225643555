
import { useEffect, useState, useContext } from "react";
import { baseUrl } from "../../connections";
import { UserContext } from "../../context/UserContext";

export const useRegions = () => {
    const { userDetails } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [payloads, setPayloadsreligion] = useState('');
    const [page, setpage] = useState(0);
    const [limit, setlimit] = useState(10);
    const [search, setsearch] = useState(null);
    const [stateChange, setStateChange] = useState(false)
    const [count, setcount] = useState(10)

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-auth-token", userDetails?.token);
    useEffect(() => {
        if (payloads !== '') {
            setIsLoading(true);
            const params = JSON.stringify(payloads, page, limit, search);
            const requestOptions = {
                method: "GET",
                headers: headers,
                redirect: "follow",
            };

            fetch(`${baseUrl}/religion/list`, requestOptions)
                .then(async (response) => {
                    const res = await response.text();
                    if (response.status !== 200) {
                        throw res;
                    }
                    return JSON.parse(res);
                })
                .then((result) => {
                    setData(result?.data);
                    setError(false);
                    //   setcount(result?.count);
                    //   setIsLoading(false);
                })
                .catch((err) => {
                    setError(err);
                    setIsLoading(false);
                });
        }
    }, [stateChange, payloads, page, limit, search]);

    return {
        ReligionData: data,
        isLoading,
        error,
        setPayloadsreligion,
        setpage,
        setlimit,
        setsearch,
        count,
        setStateChange,
        stateChange,
        page,
        limit
    };
};
