import { useEffect, useState, useContext } from "react";
import { baseUrl } from "../../connections";
import { UserContext } from "../../context/UserContext";

export const useMail = () => {
  const { userDetails } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [payload, setMailPayload] = useState(null);
  const [updateMailFav, setUpdateMailFav] = useState();
  const [redeemError, setRedeemError] = useState(false);
  const [isRedeem, setIsRedeem] = useState(false);
  const [showpoints, setShowpoints] = useState(false)
  const [redeemMessage, setRedeemMessage] = useState('');

  const [updateMailRedeem, setUpdateMailRedeem] = useState();
  const [count, setCount] = useState(0);
  const [mailURL, setMailURL] = useState('');
  const [mailHTML, setMailHTML] = useState('');

  const [reccurLoad, setReccurLoad] = useState(true);
  const onMailLoad = () => {
    setIsLoading(true);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-auth-token", userDetails?.token);

    const params = JSON.stringify(payload);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(`${baseUrl}/mail/list?${payload}`, requestOptions)
      .then(async (response) => {
        const res = await response.text();
        if (response.status !== 200) {
          throw res;
        }
        return JSON.parse(res);
      })
      .then((result) => {
        setData([...result.data]);
        setCount(result?.count);
        setError(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }

  // useEffect(() => {
  //   if (payload) {
  //     onMailLoad();
  //   }
  //   if (reccurLoad) {
  //     const interval = setInterval(() => onMailLoad(), 180000)
  //     return () => {
  //       clearInterval(interval);
  //     }
  //   }
  // }, [payload, reccurLoad]);
  useEffect(() => {
    let interval;
    // console.log(payload, "payloadpayloadpayloadpayload")
    if (reccurLoad && payload && payload !== null && JSON.stringify(payload) !== "null") {
      onMailLoad();
    }
    if (reccurLoad && payload && payload !== null && JSON.stringify(payload) !== "null") {
      interval = setInterval(() => onMailLoad(), 180000);
    } else {
      // Clear the interval when reccurLoad is false
      clearInterval(interval);
    }

    return () => {
      // Cleanup to avoid memory leaks
      clearInterval(interval);
    };
  }, [payload, reccurLoad]);


  useEffect(() => {
    if (updateMailFav) {
      // setIsLoading(true);
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("x-auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmY0YWQ4OGNjMDA1ZWRjMWU2ZGRjZGEiLCJ1dWlkIjoiQ04tN0MyRkQ4N0UiLCJncm91cCI6ImNvbnN1bWVyIiwiZW1haWwiOiJhYXNoaWZAc2NpZW5zdGVjaG5vbG9naWVzLmluIiwibW9iaWxlIjoiKzkxODg3Nzk5NzczOCIsImlhdCI6MTY2NDE3NTQyM30.UA3bcXurKzO3mOISJroE8r_V4kYDhdpLPXM8HNrBml4");

      const params = JSON.stringify(updateMailFav);

      const requestOptions = {
        method: "PUT",
        headers: headers,
        body: params,
        redirect: "follow",
      };

      fetch(`${baseUrl}/mail/update/favourite`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) => {
          if (payload && payload !== null && JSON.stringify(payload) !== "null") {
            onMailLoad();
          }
          setError(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [updateMailFav]);

  // useEffect(() => {
  //   if (updateMailRedeem) {
  //     if (!updateMailRedeem?.mail_uuid) return;
  //     // setIsLoading(true);

  //     const headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     headers.append("x-auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmY0YWQ4OGNjMDA1ZWRjMWU2ZGRjZGEiLCJ1dWlkIjoiQ04tN0MyRkQ4N0UiLCJncm91cCI6ImNvbnN1bWVyIiwiZW1haWwiOiJhYXNoaWZAc2NpZW5zdGVjaG5vbG9naWVzLmluIiwibW9iaWxlIjoiKzkxODg3Nzk5NzczOCIsImlhdCI6MTY2NDE3NTQyM30.UA3bcXurKzO3mOISJroE8r_V4kYDhdpLPXM8HNrBml4");

  //     const params = JSON.stringify(updateMailRedeem);

  //     const requestOptions = {
  //       method: "PUT",
  //       headers: headers,
  //       body: params,
  //       redirect: "follow",
  //     };
  //     setIsRedeem(false)
  //     setShowpoints(false)
  //     fetch(`${baseUrl}/mail/update/redeem`, requestOptions)
  //       .then(async (response) => {
  //         const res = await response.text();
  //         if (response.status !== 200) {
  //           throw res;
  //         }
  //         return JSON.parse(res);
  //       })
  //       .then((result) => {
  //         setRedeemMessage(result?.message);

  //         setRedeemError(false);
  //           setIsRedeem(true);
  //         setTimeout(() => {
  //           setShowpoints(true);
  //           onMailLoad();
  //         }, 15000);

  //         setRedeemMessage(result);

  //       })
  //       .catch((err) => {
  //         setRedeemError(true);

  //         setIsRedeem(true);
  //         setShowpoints(true)
  //       });
  //   }
  // }, [updateMailRedeem]);


  useEffect(() => {
    if (mailURL) {
      if (!mailURL) return;
      // setIsLoading(true);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("x-auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmY0YWQ4OGNjMDA1ZWRjMWU2ZGRjZGEiLCJ1dWlkIjoiQ04tN0MyRkQ4N0UiLCJncm91cCI6ImNvbnN1bWVyIiwiZW1haWwiOiJhYXNoaWZAc2NpZW5zdGVjaG5vbG9naWVzLmluIiwibW9iaWxlIjoiKzkxODg3Nzk5NzczOCIsImlhdCI6MTY2NDE3NTQyM30.UA3bcXurKzO3mOISJroE8r_V4kYDhdpLPXM8HNrBml4");

      const params = JSON.stringify(mailURL);

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: params,
        redirect: "follow",
      };

      fetch(`${baseUrl}/mail/read/html/file`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) => {
          setMailHTML(result?.htmlData)
        })
        .catch((err) => {
          console.log('result', err);
        });
    }
  }, [mailURL]);

  return {
    mailList: data,
    mailCount: count,
    isMailLoading: isLoading,
    mailError: error,
    redeemError,
    isRedeem,
    showpoints,
    setShowpoints,
    redeemMessage,
    mailHTML,
    setMailPayload,
    setUpdateMailFav,
    setUpdateMailRedeem,
    setMailURL,
    setReccurLoad
  };
};
