import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, ThemeProvider } from "@mui/material";
import uuid from "react-uuid";
import useGetColors from "../../utils/colors";
import { theme } from "./index.styles";
import './tabs.css';

function TabPanel(props) {
  const { children, value, name, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel`}
      aria-labelledby={`${name}-tabpanel`}
      {...other}
      style={{ cursor: "pointer" }}
    >
      {children}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(name) {
  return {
    id: `tab-btn-${name}`,
    "aria-controls": `${name}-tabpanel`,
  };
}

const TabView = ({ tabs, renderActiveTabContent, onTabChane, subUuid, setTabindexs, tabIndex = 0 }) => {
  const { tabViewColors } = useGetColors();

  const [value, setValue] = useState(tabIndex);

  useEffect(() => {
    setValue(tabIndex)
  }, [tabIndex])

  const handleChange = (_e, newValue) => {
    setValue(newValue);
    setTabindexs(newValue)
    // onTabChane(_e, tabs[newValue].uuid)
    onTabChane(_e, tabs[newValue])
  };


  return (

    <ThemeProvider theme={theme}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="scrollable"
        // scrollButtons="auto"
        tabViewColors={tabViewColors}
        centered
      >
        {tabs?.map((item) => (
          <Tab
            key={uuid()}
            label={`${item?.name}`}
            {...a11yProps(item?.name)}
            tabViewColors={tabViewColors}
          />
        ))}

      </Tabs>

      {tabs?.map((item, idx) => (
        <TabPanel key={uuid()} value={value} name={item?.name} index={idx}>
          {renderActiveTabContent(tabs[value])}
        </TabPanel>
      ))}
    </ThemeProvider>
  );
};

export default TabView;
